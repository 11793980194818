import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory 
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';


export default function (props){
    const history=useHistory();

   const {Saveimage,CancelBTN,UploadLoading,CatalogID,AddHotSpot}=props;

    function returnhtml(){
        
    }



    function LogOut(){
        localStorage.setItem("token",null);
        history.push("/login")

    }
    function HomePage(){
      
        history.push("/")

    }
    

 
    


    return(
        <div className="TopSession d-flex justify-content-between bd-highlight">
        <div className="p-2 bd-highlight">
            <Button disabled={UploadLoading} onClick={AddHotSpot} className="SaveBTN2"><img src="Add.png" />הוספת Hot spot </Button>
        
        </div>
        <div className="p-2 bd-highlight">
            
            <img onClick={HomePage} className="TOPV2LOGO" src="logotop.png"/>
        </div>
        <div className="p-2 bd-highlight">
        <Button disabled={UploadLoading} onClick={Saveimage} className="SaveBTN"><img src="Savebtn.png" />{UploadLoading ? "טוען ..." : " שמירה"}  </Button>
        <Link to={{
                        pathname:"/AddCatalog",
                        state:{
                            CatalogID:CatalogID,
                            Exist:true
                            
                        }
                    }}>
        <Button   className="CancelBTN">ביטול</Button>

        </Link>
           


        </div>
      </div>

    );




}


