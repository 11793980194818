import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory 
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';


export default function (props){
    const {Version,show,setShow,LinkAndDescreptions,SetHotSpotDescription,SetHotSpotLink}=props;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    
if(Version==="V1"){
    return(
        <>
           <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hotspotתיאור ה</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Control className="HotStopInput p-2" onChange={(e)=>{SetHotSpotDescription(e.target.value)}} value={LinkAndDescreptions['HotSpotDescription']} type="text" placeholder="הוספת תיאור חדש" />
        <div className="margin-top"></div>
        <Form.Control className="HotStopInput p-2" onChange={(e)=>{SetHotSpotLink(e.target.value)}} value={LinkAndDescreptions['HotSpotLink']} type="text" placeholder="הוספת קישור" />

        </Modal.Body>
        <Modal.Footer>
        
          <Button className="SaveHotspotBTN" onClick={handleClose}>
            אישור
          </Button>
        </Modal.Footer>
      </Modal>
        </>

    );

}

   




}


