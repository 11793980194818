import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';


export default function (props){

    const {SetNum,Num}=props;

 
    


    return(
        <>
            <Col md={2} >
            <FloatingLabel controlId="floatingInput" label="מספר עמודות" >
            <Form.Control type="text" value={Num} onChange={(e)=>{SetNum(e.target.value)}}  className="CatalogFORM" placeholder="name@example.com" />
            </FloatingLabel>

            </Col>
        </>

    );




}


