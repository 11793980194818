import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory 
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';


export default function (props){
    
    const {id,index,remove,BelongToCatalogid,ImageLeft,ImageRight}=props;

 
    function EditCatalog(){
        alert(index);
    }


    return(
        <>
        {index+1}.

           <div className="Cube">
               <Row>
               <Col >
               
                   <div class="rightcube">
                       {ImageRight ? <img className="ImageCubeReview" src={"https://"+ImageRight} /> : false}

                   </div>

               </Col>
               <Col >
                    <div class="leftcube">
                             {ImageLeft ? <img className="ImageCubeReview" src={"https://"+ImageLeft} /> : false}

                   </div>
               </Col>

               </Row>
              
                    <img onClick={()=>{remove(id)}} className="delCube" src="delete.png" />
                    <Link to={{
                        pathname:"/AddimagesScreen",
                        state:{
                            CatalogID:BelongToCatalogid,
                            ImageLeft:ImageLeft,
                            ImageRight:ImageRight,
                            CubeID:id
                        }
                    }}
                    
                    ><Button className="editBTN">עריכת עמוד</Button>
                    
                    </Link>
              

           </div>
        </>

    );




}


