import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory 
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Topmenu from './templateparts/topmenu';
import {Sendreq} from '../req'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default function (props){
    const [LoadingCatalog,SetLoadingCatalog]=useState(false);
    const [Catalogs,SetCatalogs]=useState([]);


    const history = useHistory();

    function redirect(){
        history.push('/AddCatalog');

    }

    useEffect(async()=>{
        SetLoadingCatalog(true);
        var catalogs=await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/GetCatalogs",{});
        if(catalogs !==false){
            SetCatalogs(catalogs);
        }
        SetLoadingCatalog(false);

        ///console.log(catalogs);


        



    },[])

    function ConvertDate(str){
        
            const dateString = str; // ISO8601 compliant dateString
            const D = new Date(dateString);
          
            return D.getDate()+"/"+ (D.getMonth() + 1)+"/"+D.getFullYear();
          
          
          

    }


    
async function removeCatalog(catalogid,catalogna){
    /////alertify.alert().setHeader('<h3 class="errormessagealert">שגיאה!</h3>').setting({'label':'המשך'}).setContent("הסליקה נכשלה . אנא בדוק את פרטי האשראי ונסה שנית").show();
    alertify.confirm('', 'Confirm Message', async function(){ await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/RemoveCategory",{CatalogID:catalogid});   SetCatalogs(Catalogs => (
        
        Catalogs.filter((value, i) => value.id !== catalogid)
      )); }
    , function(){ }).setContent("<spna class='ConfrimDelMessage'><div class='absu'></div>האם אתה בטוח שברצונך למחוק את <label class='Boldred'>"+catalogna +"</label> לצמיתות ?<span>").set('labels', {ok:'כן', cancel:'לא'}).show();
   /// alert(catalogid);

}

    return(
        <>
        <Topmenu version={"V1"}/>

        <Container>
            <div className="CatalogPageContect">

        <Row> 
            <Col className="SP3COL" md={3}><span className="spantitle">רשימת קטלוגים</span></Col>
            <Col md={4}>
            <Link to={{
                        pathname:"/AddCatalog",
                        state:{
                            CatalogID:null,
                            Exist:false
                            
                        }
                    }}>
                <Button  className="btnadd" > <img className="btnbpluseimage" src="plusbtn.png" />יצירת קטלוג חדש</Button>{' '}
                
                </Link>
                </Col>   
        </Row>
        {LoadingCatalog && "טוען קטלוגים .."}
        <Row style={{marginTop:"65px"}}>

            {Catalogs.map((item,index)=>
            <Col className="ColPadding" md={3}>
                
            <div className="CatalogBoxes">
                
                    <img onClick={()=>{removeCatalog(item.id,item.CatalogName)}} src="Trashsvg.svg" className="RemoveAllCatalog"  />


               
            <Link to={{
                        pathname:"/AddCatalog",
                        state:{
                            CatalogID:item.id,
                            Exist:true
                            
                        }
                    }}>
                <div className="imgSession">
                        <img src={"https://"+item.ShowImage} />
                        <div className="overplay">
                            <h4>לצפייה בקטלוג</h4>
                        </div>

                    </div>

                    </Link>
                    <div className="TextSession">
                        <div className="sessionrigth">
                        <h3>{item.CatalogName}</h3>
                        <span>{ConvertDate(item.Date)}</span>
                        </div>

                        <div className="sessionleft">
                            <a  onClick={()=>{
                                var Link="http://catalog.motsesim.co.il//?catalogid="+item.id;
                                var TempText = document.createElement("input");
                                TempText.value = Link;
                                document.body.appendChild(TempText);
                                TempText.select();
                                
                                document.execCommand("copy");
                                document.body.removeChild(TempText);
                                alertify.alert('', 'Alert Message!', function(){  }).setContent("<div class='absu2'></div><spna class='ErrorMessage'>לינק הקטלוג הועתק בהצלחה! יש ללחוץ על הדבק לקבלתו.<span>").set('label', 'אישור').show();



                            }}><img src="link.png" /></a>

                            </div>

                     </div>   

            </div>

        </Col>
            
            
            )}
            
            

        </Row>


        </div>
        </Container>
           
        </>

    );




}


