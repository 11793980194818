import React from 'react';
import { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
} from "react-router-dom";
import { Button, Row, Container, Col, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Gallerycube from './templateparts/gallerycube';
import AddCubeSession from './templateparts/AddCubeSession';
import { Sendreq } from '../req'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default function (props) {

    const [CubeArray, SetCubeArray] = useState([]);

    const { catalogID, Exist, Cubes, Addnum, Num, SetNum } = props;

    useEffect(() => {
        if (Exist) {
            SetCubeArray(Cubes);
        }

    }
        , [Cubes])


    useEffect(async () => {
        if (Addnum > 0) {
            //alert("מוסיף!")
            var numtoadd = Addnum - CubeArray.length;

            if (numtoadd <= 0) {
                alert("לא ניתן להוסיף")
            }
            else {
                for (var i = 0; i < numtoadd; i++) {
                    await AddToarray({});

                }
                SetNum(CubeArray.length)

            }


            // SetNum(parseInt(Num)+parseInt(Addnum));

        }

    }, [Addnum])

    useEffect(() => {
        if (CubeArray) {
            SetNum(CubeArray.length)
        }



    }, [CubeArray])

    async function AddToarray(newValue) {

        const data = await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/OpenNewCube", { catalogID: catalogID });
        if (data !== false) {
            var addcube = { "id": data, "BelongToCatalogid": catalogID, "ImageLeft": null, "ImageRight": null };
            // SetNum(Num+1);
            //SetNum(parseInt(Num)+1);

        }
        else {
            alertify.alert('', 'Alert Message!', function () { }).setContent("<div class='absu'></div><spna class='ErrorMessage'>הוספת העמודה כשלה<span>").set('label', 'אישור').show();

            ///alert("הוספת העמודה כשלה");
            return;
        }



        SetCubeArray(CubeArray => [...CubeArray, addcube]);
    }
    async function removeObject(objectID) {
        ///SetCubeArray(CubeArray.filter((item,index) => index !== objectIndex));
        //console.log("מחק את :");
        console.log(objectID);
        const remove = await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/RemoveCube", { Cubeid: objectID });
        if (remove) {
            SetCubeArray(CubeArray.filter((item, index) => item.id !== objectID));
            alertify.alert('', 'Alert Message!', function () { }).setContent("<div class='absu2'></div><spna class='ErrorMessage'>העמודה נמחקה בהצלחה!<span>").set('label', 'אישור').show();
            //SetNum(parseInt(Num)-1);
            ///alert("");
        }
        else {
            alertify.alert('', 'Alert Message!', function () { }).setContent("<div class='absu'></div><spna class='ErrorMessage'>מחיקת העמודה כשלה<span>").set('label', 'אישור').show();


        }

        console.log(remove);


    }






    return (
        <Row>
            {/*   {Cubes && Cubes.map((item,index)=>
            <Col md={4}><Gallerycube remove={removeObject} {...item} key={index} index={index}/></Col>
            )} */}


            {CubeArray && CubeArray.map((item, index) =>

                <Col md={4}><Gallerycube remove={removeObject} {...item} key={index} index={index} /></Col>

            )}






            <Col md={4}>
                <AddCubeSession AddToarray={AddToarray} />
            </Col>
        </Row>
    );




}


