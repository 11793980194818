import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Topmenu from './templateparts/topmenu';
import CatalogColums from './CatalogColums';
import {Sendreq} from '../req'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import ColNumber from './templateparts/ColNumber';

export default function (props){

    const [CatalogName,SetCatalogName]=useState("");
   
    const [added,Setadded]=useState(false);
    const [catalogID,SetcatalogID]=useState(null);
    const location=useLocation();
    const {CatalogID,Exist}=location.state;
    const [GeneralObject,SetGeneralObject]=useState([]);
    const [Toggle,SetToggle]=useState(true);
    const [Num,SetNum]=useState(0);
    const [Addnum,SetAddnum]=useState(0);




    useEffect(async ()=>{
        
        if(Exist){

            var GetThecatalog= await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/GetCatalog",{catalogid:CatalogID});
            console.log(GetThecatalog);
            SetcatalogID(CatalogID);
            Setadded(true);
            SetCatalogName(GetThecatalog.CatalogData[0].CatalogName)
            SetGeneralObject(GetThecatalog);
            ///console.log();
            SetNum(GetThecatalog.Cubs.length)
            if(GetThecatalog.CatalogData[0].Visible ==="1"){
                SetToggle(true);
            }
            else{
                SetToggle(false);

            }
            






        }

    },[])

     async function  SetTogglereq(){
        if(Toggle){ 
            const toggle=await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/VisibleCatalog",{catalogid:catalogID,bool:0});
            SetToggle(false); 

        }
        else{
            const toggle=await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/VisibleCatalog",{catalogid:catalogID,bool:1});
            SetToggle(true);
        }
           
     



    }





    
 /*    function CheckNumSize(){

        if(Num<GeneralObject.Cubs.length){
            alert("לא ניתן לבחור מספר עמודות קטן מהקיים ");
            return false;
        }
        return Num-GeneralObject.Cubs.length;

    } */
 
    async function AddtheGallery(){
        ///CheckNumSize();
        

        if(added===false){
        
        if(CatalogName==='' ){
            alertify.alert('', 'Alert Message!', function(){  }).setContent("<div class='absu'></div><spna class='ErrorMessage'>יש למלא את כל השדות<span>").set('label', 'אישור').show();
            ///alert("");
            return;
        }
        const data=await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/Addcatalog",{edit:added,CatalogName:CatalogName});
        console.log(data);
        if(data===false){
            alertify.alert('', 'Alert Message!', function(){  }).setContent("<div class='absu'></div><spna class='ErrorMessage'>הוספת הקטלוג כשלה!<span>").set('label', 'אישור').show();

            
            return;
        }
        if(data==='Exist'){
            alertify.alert('', 'Alert Message!', function(){  }).setContent("<div class='absu'></div><spna class='ErrorMessage'>שם הקטלוג כבר קיים , בחר שם אחר!<span>").set('label', 'אישור').show();
          
            return;
        }
        
        Setadded(true);
        SetcatalogID(data);
    }
    else{
        SetAddnum(Num);

        
        ///alert(CheckNumSize());
        





        const data=await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/Addcatalog",{edit:added,catalogID:catalogID,CatalogName:CatalogName});
        if(data !==false){
           
            alertify.alert('', 'Alert Message!', function(){  }).setContent("<div class='absu2'></div><spna class='ErrorMessage'>הקטלוג עודכן בהצלחה<span>").set('label', 'אישור').show();
           
            ///alert("הקטלוג עודכן בהצלחה!");
        }
        else{
            alertify.alert('', 'Alert Message!', function(){  }).setContent("<div class='absu'></div><spna class='ErrorMessage'>עדכון הקטלוג כשל<span>").set('label', 'אישור').show();
          
          
        }

    }

    

    }


    return(
        <>
        {added ? <Topmenu version={"V4"} toggle={Toggle} Settoggle={SetTogglereq} /> : <Topmenu version={"V2"} />  }
        
        <Container>
     
        <Row style={{marginTop:"55px"}}>
            <Col md={4}>
            <FloatingLabel controlId="floatingInput" label="שם הקטלוג" >
            <Form.Control type="text" value={CatalogName} onChange={(e)=>{SetCatalogName(e.target.value)}}  className="CatalogFORM" placeholder="name@example.com" />
            </FloatingLabel>

            </Col>

            {added ? <ColNumber Num={Num} SetNum={SetNum} /> :false}


            
           {/*  <Col md={2}>
            <FloatingLabel controlId="floatingInput" label="מספר כפולות" >
            <Form.Control type="number" value={Num} onChange={(e)=>{SetNum(e.target.value)}} className="CatalogFORM" placeholder="name@example.com" />
            </FloatingLabel>

            </Col> */}
            <Col md={3}>
            <Button onClick={AddtheGallery} className="LetStartBTN">{added ? "עדכן" : "בואו נתחיל"}</Button>

            </Col>
        </Row>


            {added ? <CatalogColums Addnum={Addnum} Num={Num} SetNum={SetNum} Exist={Exist} Cubes={GeneralObject.Cubs} catalogID={catalogID} /> : false}
        </Container>
            
        </>

    );




}


