import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory 
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';


export default function (props){

    const {AddToarray}=props;

    function clicked(){
        AddToarray({"ref":"2"});
    }

 
    


    return(
        <div onClick={clicked} className="AddonSe">
        <div className="AddonCube">
            <img src="addArrow.png" />
            
            </div>
            <label>הוספת עמודה</label>
        </div>

    );




}


