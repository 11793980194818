import React from 'react';
import { useState, useEffect, useReducer } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { Button, Row, Container, Col, Form, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Topmenu from './templateparts/topmenu';
import TopmenuV2 from './templateparts/topmenuV2';
import { SendFile, Sendreq } from '../req'
import TopmenuV3 from './templateparts/topmenuV3'
import { Rnd } from "react-rnd";
import { render } from "react-dom";
import HotSpots from './templateparts/HotSpots';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import Popupcenter from './templateparts/PopUpCenter';

export default function (props) {
  const [pictureright, setPictureright] = useState(null);
  const [imgDataright, setImgDataright] = useState(null);
  const [pictureleft, setPictureleft] = useState(null);
  const [imgDataleft, setImgDataleft] = useState(null);
  const [CanUpload, SetCanUpload] = useState(false);
  const [UploadLoading, SetUploadLoading] = useState(false);
  const [DataBaseSave, SetDatabaseSave] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [uniqID, SetuniqID] = useState("");
  const [HotSoptObject, SetHotSoptObject] = useState([]);
  const [NumberStart, SetNumberStart] = useState(0);
  const { CatalogID, CubeID, ImageLeft, ImageRight } = location.state;
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  //// const [state, setState] = useState({x:0, y:0, width:300, height:300});
  const [hotSpots, SetHotSpots] = useState([]);
  const [show, setShow] = useState(false);
  ///
  function removeRigth() {
    setPictureright(null);
    setImgDataright(null);
    //alert("למחוק ימין")
  }
  function removeLeft() {
    console.log('removeLeft')
    setPictureleft(null);
    setImgDataleft(null);
    console.log(pictureleft,imgDataleft)
    //alert("למחוק ימין")
  }
  useEffect(async () => {
    if (ImageLeft !== null && ImageRight !== null) {
      setImgDataright("https://" + ImageRight);
      setImgDataleft("https://" + ImageLeft);
      var HotSpots = await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/GetHotSpots", { CubeID: CubeID });
      var element = document.getElementsByClassName("playerProfilePic_home_tile")[0];
      var height = element.offsetHeight;
      var width = element.offsetWidth;
      if (HotSpots !== false) {
        for (const element of HotSpots) {
          var x = (element.LeftPercent / 100) * width;
          var y = (element.TopPercent / 100) * height;
          var Elementwidth = (element.WidthPercent / 100) * width;
          var Elementheight = (element.HeightPercent / 100) * height;
          var object = { x: x, y: y, width: Elementwidth, height: Elementheight };
          var object2 = { HotSpotDescription: element.HotSpotDescription, HotSpotLink: element.HotSpotLink }
          SetHotSoptObject(HotSoptObject => [...HotSoptObject, object2]);
          SetHotSpots(hotSpots => [...hotSpots, object])
        }
      }
      //SetHotSpots(hotSpots => [...hotSpots, obj])
    }
  }, [])
  useEffect(() => {
    console.log('changed');
  }, [pictureleft, pictureright, imgDataleft,imgDataright])
  function CheckIndex(index, index2) {
    console.log(index);
    console.log(index2);
    return index !== index2;
  }
  function removeHotspot(object1) {
    SetHotSpots(hotSpots => (
      hotSpots.filter((item, i) => CheckIndex(i, object1))
    ));
  }
  const onChangePictureleft = e => {
    /// alert(e.target.files[0]);
    console.log(e.target.files[0], 'here');
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      setPictureleft(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgDataleft(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onChangePictureright = e => {
    /// alert(e.target.files[0]);
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      setPictureright(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgDataright(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  function CancelBTN() {
    ///alert("Cancel!!!");
  }
  function AddHotSpot() {
    var obj = { x: 0, y: 0, width: 300, height: 300 };
    var object2 = { HotSpotDescription: "", HotSpotLink: "" }
    SetHotSpots(hotSpots => [...hotSpots, obj]);
    SetHotSoptObject(HotSoptObject => [...HotSoptObject, object2]);
  }
  async function Saveimage() {
    console.log('saving')
    forceUpdate()
    SetDatabaseSave(false);
    SetuniqID(Math.floor(Math.random() * 100000).toString());
    /*   if(imgDataright ===null || imgDataleft===null){
        alertify.alert('', 'Alert Message!', function(){  }).setContent("<div class='absu'></div><spna class='ErrorMessage'>יש לעלות תמונה ימינית תמונה שמאלית טרם השמירה<span>").set('label', 'אישור').show();
        return;
      } */
    SetUploadLoading(true);
    if (pictureleft !== null || pictureright !== null) {
      var sendpicright = null;
      var sendpicleft = null;
      if (imgDataright !== null) {
        sendpicright = await SendFile("https://motsesim.wdev.co.il/api/admin/Menagment/FileUpload", pictureright);
      }
      if (pictureleft !== null) {
        sendpicleft = await SendFile("https://motsesim.wdev.co.il/api/admin/Menagment/FileUpload", pictureleft);
      }
      if (sendpicleft === false || sendpicright === false) {
        alert("אחת או יותר מהעלאת התמונות כשלה");
        return;
      }
      var updateCube = await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/UpdateCubeImages", { Linkright: sendpicright, LinkLeft: sendpicleft, Cubeid: CubeID, Catalogid: CatalogID });
    }
    ///alert(CatalogID + "");
    setTimeout(() => {
      SetNumberStart(NumberStart + 1);
      ///  SetDatabaseSave(true);
      //var updateCube=await Sendreq("https://motsesim.wdev.co.il/api/admin/Menagment/UpdateCubeImages",{Linkright:sendpicright,LinkLeft:sendpicleft,Cubeid:CubeID,Catalogid:CatalogID});
      history.push({
        pathname: "/AddCatalog",
        state: {
          CatalogID: CatalogID,
          Exist: true
        }
      })
      SetUploadLoading(false);
      /// SetDatabaseSave(true);
      alertify.alert('', 'Alert Message!', function () { }).setContent("<div class='absu2'></div><spna class='ErrorMessage'>העדכונים נשמרו בהצלחה<span>").set('label', 'אישור').show();
    }, 2000);
    ///alert("התמונות הועלו בהצלחה!");
  }
  function hotspotLinks() {
    setShow(true);
    /// alertify.alert().setHeader('<h3 class="errormessagealert">שגיאה!</h3>').setting({'label':'המשך'}).setContent("הסליקה נכשלה . אנא בדוק את פרטי האשראי ונסה שנית").show();
  }
  useEffect(() => {
    console.log(hotSpots);
  }, [hotSpots])
  return (
    <>
      {/*  <TopmenuV3 /> */}
      <TopmenuV2 AddHotSpot={AddHotSpot} UploadLoading={UploadLoading} Saveimage={Saveimage} CatalogID={CatalogID} CancelBTN={CancelBTN} />
      <Row className="MainScreen">
        {hotSpots && hotSpots.map((item, index) =>
          <HotSpots SetHotSpots={SetHotSpots} NumberStart={NumberStart} uniqID={uniqID} HotSoptObject={HotSoptObject} DataBaseSave={DataBaseSave} hotspotLinks={hotspotLinks} Startobject={item} removeHotspot={removeHotspot} CubeID={CubeID} index={index} />
        )}
        <Col className="SpCOL" >
          <div className="boxRIGHT">
            {imgDataright !== null && <img onClick={removeRigth} className="removeBTN" src="Trashsvg.svg" />}
            {imgDataright !== null ? <img className="playerProfilePic_home_tile img-fluid" src={imgDataright} /> : <img className="img-fluid" src="greyright.png" />}
            <div class="image-upload">
              <label for="file-input">
                {imgDataright === null && <img src="arrow.png" />}
              </label>
              <input id="file-input" type="file" onClick={onChangePictureright} onChange={onChangePictureright} type="file" />
            </div>
          </div>
        </Col>
        <Col className="SpCOL">
          <div className="boxLEFT">
            {imgDataleft !== null && <img onClick={removeLeft} className="removeBTN" src="Trashsvg.svg" />}
            {imgDataleft !== null ? <img className="playerProfilePic_home_tile img-fluid" src={imgDataleft} /> : <img className="img-fluid" src="greyleft.png" />}
            <div class="image-upload">
              <label for="file-input2">
                {imgDataleft === null && <img src="arrow.png" />}
              </label>
              <input id="file-input2" type="file" onClick={onChangePictureleft} onChange={onChangePictureleft} type="file" />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}


